import React, { ReactNode, useId } from "react";
import {
	FormInputWrapper,
	Item,
	Select as SolisSelect,
	SelectProps as SolisSelectProps,
	useFormWrapper,
} from "@withjuly/solis";

export interface SelectProps
	extends Omit<
		SolisSelectProps,
		"value" | "setValue" | "onChange" | "children"
	> {
	label?: string;
	name: string;
	onChange?: (value: string) => void;
	options: { label: ReactNode; value: string; disabled?: boolean }[];
}

export const Select: React.FC<SelectProps> = ({
	name,
	label,
	onChange,
	options,
	...rest
}) => {
	const id = useId();
	const { register, setValue, value } = useFormWrapper(name);
	const form = register(name);

	return (
		<FormInputWrapper id={id} label={label} name={name}>
			<SolisSelect
				setValue={(value) => {
					onChange?.(value);
					setValue(value);
				}}
				value={value}
				{...rest}
				{...form}
			>
				{options.map(({ label, value, disabled }) => (
					<Item key={value} value={value} disabled={disabled}>
						{label}
					</Item>
				))}
			</SolisSelect>
		</FormInputWrapper>
	);
};
