import React, { useId } from "react";
import { FormInputWrapper, useFormWrapper } from "@withjuly/solis";
import { DropdownInput } from "@withjuly/solisv2";

interface RootProps extends DropdownInput.RootProps {
	name: string;
}
export const ZodDropdownInput: React.FC<RootProps> = ({
	name,
	label,
	onValueChange,
	children,
	...rest
}) => {
	const id = useId();
	const { register, setValue, value } = useFormWrapper(name);
	const form = register(name);

	return (
		<FormInputWrapper id={id} name={name}>
			<DropdownInput.Root
				value={value}
				onValueChange={(value) => {
					setValue(value);
					onValueChange?.(value);
				}}
				label={label}
				{...rest}
				{...form}
			>
				{children}
			</DropdownInput.Root>
		</FormInputWrapper>
	);
};
