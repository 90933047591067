import React, { useId } from "react";
import {
	FormInputWrapper,
	useFormWrapper,
	LabeledTextInput as SolisLabeledTextInput,
	LabeledTextInputProps,
} from "@withjuly/solis";

export interface SolisLabeledTextInputProps extends LabeledTextInputProps {
	name: string;

	label?: string;
	help?: string;

	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	setValueAs?: (value: string) => any;

	inputLabel?: string;
}

export const LabeledTextInput: React.FC<SolisLabeledTextInputProps> = ({
	name,
	help,
	label,
	onChange,
	onBlur,
	setValueAs,
	inputLabel,
	...rest
}) => {
	const id = useId();
	const { register, error, hasError } = useFormWrapper(name);
	const form = register(name, {
		onChange,
		onBlur,
		setValueAs,
	});

	const describedByIds: string[] = [];
	if (help) {
		describedByIds.push(id + "-help");
	}
	if (error?.message) {
		describedByIds.push(id + "-error");
	}

	return (
		<FormInputWrapper id={id} name={name} label={label} help={help}>
			<SolisLabeledTextInput
				isInvalid={hasError}
				aria-describedby={describedByIds.join(" ")}
				label={inputLabel}
				{...rest}
				{...form}
			/>
		</FormInputWrapper>
	);
};
